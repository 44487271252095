import React from "react";
import "./Thankyou.css";
import thankyou_icon from "../Assets/Common/thankyou.webp";
import facebook from "../Assets/Social-Links/facebook.svg";
import instagram from "../Assets/Social-Links/instagram.svg";
import youtube from "../Assets/Social-Links/youtube.svg";
import twitter from "../Assets/Social-Links/twitter.svg";
import linkedin from "../Assets/Social-Links/linkedin.svg";
import Helmet from "../Components/Common/Helmet/Helmet";

export default function Thankyou() {
    return (
        <>
            <Helmet title="Thank You">
                {/* Google Tag Manager Script */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16756686591"></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'AW-16756686591');
                        
                        gtag('event', 'conversion', {
                            'send_to': 'AW-16756686591/qENpCOHY4eIZEP_9mrY-',
                            'value': 1.0,
                            'currency': 'AED'
                        });
                    `}
                </script>
            </Helmet>

            <section id="thankyou" className="thankyou">
                <div>
                    <img className="msg-icon" src={thankyou_icon} alt="Thank you"/>
                </div>
                <h2>THANK YOU, WE'LL GET BACK TO YOU SHORTLY.</h2>
                <p>YOU CAN CALL US DIRECTLY</p>
                <a href="/"><p className="phone">+971 54 222 6464</p></a>
                <div className="social-links">
                    <a href="https://www.facebook.com/profile.php?id=61565494103415/"><img className='social' src={facebook} alt="Facebook"/></a>
                    <a href="https://www.instagram.com/rabbit_nd_carrot/"><img className='social' src={instagram} alt="Instagram"/></a>
                    <a href="https://www.youtube.com/@RabbitandCarrot-z5l/"><img className='social' src={youtube} alt="YouTube"/></a>
                    <a href="https://x.com/rabbitndcarrot/"><img className='social' src={twitter} alt="Twitter"/></a>
                    <a href="https://www.linkedin.com/company/rabbitandcarrot/"><img className='social' src={linkedin} alt="LinkedIn"/></a>
                </div> 
            </section>
        </>
    );
} 