import React from 'react'
import { motion } from "framer-motion"

import logo from "../../../Assets//Logo/RNC-Logo-black.png"
import left from "../../../Assets/Common/arrow-left1.png"
import right from "../../../Assets/Common/arrow-right1.png"
// import facebook from "../../../Assets/Social-Links/facebook.svg"
// import instagram from "../../../Assets/Social-Links/instagram.svg"
// import youtube from "../../../Assets/Social-Links/youtube.svg"
// import twitter from "../../../Assets/Social-Links/twitter.svg"
// import linkedin from "../../../Assets/Social-Links/linkedin.svg"
// import mail_icon from "../../../Assets/Common/mail.svg"
// import phone_icon from "../../../Assets/Common/phone.svg"
// import SkyLineDubai from "../../Assets/Common/SkylineDubai.jpg"

import "./Footer.css"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <img src={logo} alt=""/>
        <p className="details">We’re all about creating communications that sparkle and shine for the brands we love.
        But there’s more to us than just creating pretty pictures.</p>
        <div className="chat-on">
            <p className="whatsapp"><a href="https://wa.me/+971542226464">WHATSAPP</a></p>
            <p><div className="chat">
              <motion.img 
                initial= {{
                  opacity: 0
                }}
                animate= {{
                  opacity: 1,
                  x: -10,
                  transition: {
                    duration: 1,
                    repeat: Infinity
                  }
                }}
                src={left} alt=""/>
              CHAT ON
              <motion.img 
                initial= {{
                  opacity: 0
                }}
                animate= {{
                  opacity: 1,
                  x: 10,
                  transition: {
                    duration: 1,
                    repeat: Infinity
                  }
                }}
                src={right} alt=""/>
              </div></p>
            <p className="live-chat"><a href="#about-contact">GET IN TOUCH</a></p>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copyRight">Rabbit And Carrot © 2009 - 2024. All Rights Reserved.</p>
        <p className="policy">Terms of Use | Privacy Policy | Cookie Policy</p>
      </div>
    </footer>
  )
}
