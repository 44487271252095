import React from 'react'
import "./Navbar1.css"

import logo_white from "../Assets/Logo/RNC-Logo-white.png"
import logo_black from "../Assets/Logo/RNC-Logo-black.png"

const Navbar = () => {
  return (
    <div className="navbar-thankyou">
        <nav className="container dark-nav">
          <a href="/"><img src={logo_black} alt="" className="logo"></img></a>
          <ul className="navbar">
            <li><button className="btn1 btn2"><a href="/">Get a Quote</a></button></li>
          </ul>
        </nav>
    </div>
  )
}

export default Navbar