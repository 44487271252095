import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { AnimatePresence, motion } from 'framer-motion'

import FullScreenImage from './FullScreenImage'
import "./Gallary.css"

import play from "../../../Assets/Common/play-button.svg"
import closeButton from "../../../Assets/Common/close-button.svg"

import video1 from "../../../Assets/Works/video/1.webp"
import video2 from "../../../Assets/Works/video/2.jpg"
import video3 from "../../../Assets/Works/video/3.webp"
import video4 from "../../../Assets/Works/video/4.webp"
import video5 from "../../../Assets/Works/video/5.webp"
import video6 from "../../../Assets/Works/video/6.webp"

import twoD1 from "../../../Assets/Works/2d/1.webp"
import twoD2 from "../../../Assets/Works/2d/2.webp"
import twoD3 from "../../../Assets/Works/2d/3.webp"
import twoD4 from "../../../Assets/Works/2d/4.webp"
import twoD5 from "../../../Assets/Works/2d/5.webp"
import twoD6 from "../../../Assets/Works/2d/6.jpg"

import threeD1 from "../../../Assets/Works/3d/1.webp"
import threeD2 from "../../../Assets/Works/3d/2.jpg"
import threeD3 from "../../../Assets/Works/3d/3.jpg"
import threeD4 from "../../../Assets/Works/3d/4.jpg"
import threeD5 from "../../../Assets/Works/3d/5.jpg"
import threeD6 from "../../../Assets/Works/3d/6.webp"

import branding1 from "../../../Assets/Works/branding/1.webp"
import branding2 from "../../../Assets/Works/branding/2.webp"
import branding3 from "../../../Assets/Works/branding/3.webp"
import branding4 from "../../../Assets/Works/branding/4.webp"
import branding5 from "../../../Assets/Works/branding/5.webp"
import branding6 from "../../../Assets/Works/branding/6.webp"
import branding7 from "../../../Assets/Works/branding/7.webp"
import branding8 from "../../../Assets/Works/branding/8.webp"
import branding9 from "../../../Assets/Works/branding/9.webp"
import branding10 from "../../../Assets/Works/branding/10.webp"
import branding11 from "../../../Assets/Works/branding/11.webp"
// import branding12 from "../../../Assets/Works/branding/12.webp"

import web1 from "../../../Assets/Works/web/1.webp"
import web2 from "../../../Assets/Works/web/2.webp"
import web3 from "../../../Assets/Works/web/3.webp"
import web4 from "../../../Assets/Works/web/4.webp"
import web5 from "../../../Assets/Works/web/5.webp"
import web6 from "../../../Assets/Works/web/6.webp"
import web7 from "../../../Assets/Works/web/7.jpg"
import web8 from "../../../Assets/Works/web/8.jpg"
import web9 from "../../../Assets/Works/web/9.jpg"
import web10 from "../../../Assets/Works/web/10.jpg"
import web11 from "../../../Assets/Works/web/11.jpg"
import web12 from "../../../Assets/Works/web/12.jpg"



const videoLinks = {
    video_1: "https://youtu.be/hydng2qSHAY",
    video_2: "https://youtu.be/8zMyJV968JQ",
    video_3: "https://youtu.be/O3_cQkxyLLY",
    video_4: "https://youtu.be/tRozcrkFo78",
    video_5: "https://youtu.be/b4fqyba9NE8",
    video_6: "https://youtu.be/OUbbvRgwahI",
    video_7: "https://youtu.be/JEYTvUvRnK0",
    video_8: "https://youtu.be/a3SWwsjSs48",
    video_9: "https://youtu.be/BN6vGiHEMf4",
    video_10: "https://youtu.be/u2DnEYt11f0",
    video_11: "https://youtu.be/4bh4VPn3FG0",
    video_12: "https://youtu.be/tx4xrA6QOBw",
    video_13: "https://youtu.be/jgcjOxtK5qQ",
    video_14: "https://youtu.be/VH2AH32Mry4",
    video_15: "https://www.youtube.com/watch?v=02TpJ53Jbyg",
    video_16: "https://www.youtube.com/watch?v=AWgFs5XH3fc",
    video_17: "https://youtu.be/ydEDP_SJI9o",
    video_18: "https://youtu.be/CZ4LmhaxWsU",
    // video_19: "",
    // video_20: "",
    // video_21: "",
    // video_22: "",
    // video_23: "",
    // video_24: "",
    // video_25: "",
    // video_26: "",
    // video_27: "",
    // video_28: "",
    // video_29: "",
    // video_30: ""
}

const gallaryVariants = {
  initial: { y: 200, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  exit: { y: -200, opacity: 0, transition: { duration: .5 } },
  // initial1: { x: -100, opacity: 0 },
  // animate1: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  // exit1: { x: -100, opacity: 0, transition: { duration: .5 } },
};

export default function Gallary({activeSection, handleSectionChange}) {
    const [playingVideo, setPlayingVideo] = useState(null);

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl);
      };

    const [playingImage, setPlayingImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setPlayingImage(imageUrl);
      };

    // console.log(playingImage)
    // console.log(playingVideo)

    // const [activeSection, setActiveSection] = useState('video-production');

    // const handleSectionChange = (section) => {
    //   setActiveSection(section);
    // }

  return (
    <section id="portfolio" className="portfolio">
      <h1 className="commonHeading">Our <span>Portfolio</span></h1>
      <div className="button-container2 content-center">
        <div
          className={`toggle-button2 ${activeSection === 'video-production' ? 'active' : ''}`}
          onClick={() => handleSectionChange('video-production')}
          >
          Video Production
        </div>
        <div
          className={`toggle-button2 ${activeSection === '2d&3d-animation' ? 'active' : ''}`}
          onClick={() => handleSectionChange('2d&3d-animation')}
          >
          2D & 3D Animation
        </div>
        <div
          className={`toggle-button2 ${activeSection === 'branding' ? 'active' : ''}`}
          onClick={() => handleSectionChange('branding')}
          >
          Branding
        </div>
        <div
          className={`toggle-button2 ${activeSection === 'digital-marketing' ? 'active' : ''}`}
          onClick={() => handleSectionChange('digital-marketing')}
          >
          Digital Marketing
        </div>
        <div
          className={`toggle-button2 ${activeSection === 'web-development' ? 'active' : ''}`}
          onClick={() => handleSectionChange('web-development')}
          >
          Web Development
        </div>
      </div>
      {activeSection === "video-production" && (
        <AnimatePresence mode="wait">
          <motion.div 
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_1)}>
                <img src={video1} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">KNORR</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_2)}>
                <img src={video2} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">SOBHA</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_3)}>
                <img src={video3} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">DMB</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_4)}>
                <img src={video4} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">DERMA</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_5)}>
                <img src={video5} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">GITEX</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_6)}>
                <img src={video6} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">VIDEO PRODUCTION</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">CANBEBE</p>
                    </div>
                </div>
            </div>
          </motion.div>
        </AnimatePresence>
      )}

      {activeSection === "2d&3d-animation" && (
        <AnimatePresence mode="wait">
          <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_7)}>
              <img src={twoD1} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">LEGALTY</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_8)}>
              <img src={twoD2} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">BGX</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_9)}>
              <img src={twoD3} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">BOSSA</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_10)}>
              <img src={twoD4} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">KAREO</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_11)}>
              <img src={twoD5} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">RETUNE</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_12)}>
              <img src={twoD6} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">2D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">AUSTRALIAN GOVERNMENT</p>
                  </div>
              </div>
          </div>
          </motion.div>
          <motion.div
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_13)}>
              <img src={threeD1} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">ABU DHABI POLICE</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_14)}>
              <img src={threeD2} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">CALIBER</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_15)}>
              <img src={threeD3} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">MOK</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_16)}>
              <img src={threeD4} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">NOVITA</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_17)}>
              <img src={threeD5} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">VGO TEL</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleVideoClick(videoLinks.video_18)}>
              <img src={threeD6} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">3D ANIMATION</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">SPT</p>
                  </div>
              </div>
          </div>
          </motion.div>
        </AnimatePresence>

      )}

      {activeSection === "digital-marketing" && (
        <AnimatePresence mode="wait">
          <motion.div 
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
            <div className="image-grid-item" onClick={() => handleImageClick(video1)}>
                <img src={video1} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">DIGITAL MARKETING</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">KNORR</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleImageClick(video2)}>
                <img src={video2} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">DIGITAL MARKETING</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">SOBHA</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleImageClick(video3)}>
                <img src={video3} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">DIGITAL MARKETING</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">DMB</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleImageClick(video4)}>
                <img src={video4} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">DIGITAL MARKETING</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">DERMA</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleImageClick(video5)}>
                <img src={video5} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">DIGITAL MARKETING</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">GITEX</p>
                    </div>
                </div>
            </div>
            <div className="image-grid-item" onClick={() => handleImageClick(video6)}>
                <img src={video6} alt=""></img>
                <div className="overlay1">
                    <div className="row1">
                      <p className="name">DIGITAL MARKETING</p>
                      <img className="play-button" src={play} alt=""/>
                      <p className="company-name">CANBEBE</p>
                    </div>
                </div>
            </div>
          </motion.div>
        </AnimatePresence>
      )}

      {activeSection === "branding" && (
        <AnimatePresence mode="wait">
          <motion.div 
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
          <div className="image-grid-item" onClick={() => handleImageClick(branding1)}>
              <img src={branding1} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">CARLSBERG</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding2)}>
              <img src={branding2} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">SOTHYS</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding3)}>
              <img src={branding3} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">MORANO</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding4)}>
              <img src={branding4} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">OVIDO</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding5)}>
              <img src={branding5} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">ORGANIC YOGA</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding6)}>
              <img src={branding6} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">POLIO DIGITAL</p>
                  </div>
              </div>
          </div>
          </motion.div>
          <motion.div 
            initial="initial"
            whileInView="animate"
            transition={{
                duration: 1,
                ease: "easeOut",
                delay: 0.2
            }}
            exit="exit"
            viewport={{ once: false, amount: 0.5 }}
            variants={gallaryVariants}
            className='image-grid'>
          <div className="image-grid-item" onClick={() => handleImageClick(branding7)}>
              <img src={branding7} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">BEARD SOFTENER</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding8)}>
              <img src={branding8} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">VIBI</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding9)}>
              <img src={branding9} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">MEDI LASER</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding10)}>
              <img src={branding10} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">CLICK</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding11)}>
              <img src={branding11} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">CHEF GU</p>
                  </div>
              </div>
          </div>
          <div className="image-grid-item" onClick={() => handleImageClick(branding1)}>
              <img src={branding1} alt=""></img>
              <div className="overlay1">
                  <div className="row1">
                    <p className="name">BRANDING</p>
                    <img className="play-button" src={play} alt=""/>
                    <p className="company-name">POLIO DIGITAL</p>
                  </div>
              </div>
          </div>
          </motion.div>
      </AnimatePresence>
      )}

      {activeSection === "web-development" && (
        <AnimatePresence mode="wait">
            <motion.div  
              initial="initial"
              whileInView="animate"
              transition={{
                  duration: 1,
                  ease: "easeOut",
                  delay: 0.2
              }}
              exit="exit"
              viewport={{ once: false, amount: 0.5 }}
              variants={gallaryVariants}
              className='image-grid'>
              <div className="image-grid-item" onClick={() => handleImageClick(web1)}>
                  <img src={web1} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">CASSIDY</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web2)}>
                  <img src={web2} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">PROMINA</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web3)}>
                  <img src={web3} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">DENTAL</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web4)}>
                  <img src={web4} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">DIET BOWL</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web5)}>
                  <img src={web5} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">ZEBPAY</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web6)}>
                  <img src={web6} alt="" className="web-img"></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">IDENTITY</p>
                      </div>
                  </div>
              </div>
            </motion.div>
            <motion.div  
              initial="initial"
              whileInView="animate"
              transition={{
                  duration: 1,
                  ease: "easeOut",
                  delay: 0.2
              }}
              exit="exit"
              viewport={{ once: false, amount: 0.5 }}
              variants={gallaryVariants}
              className='image-grid'>
              <div className="image-grid-item" onClick={() => handleImageClick(web7)}>
                  <img src={web7} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">ABU STUDIO</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web8)}>
                  <img src={web8} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">CLOTHY</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web9)}>
                  <img src={web9} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">DENTAL</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web10)}>
                  <img src={web10} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">VOYAGE</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web11)}>
                  <img src={web11} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">TIKI</p>
                      </div>
                  </div>
              </div>
              <div className="image-grid-item" onClick={() => handleImageClick(web12)}>
                  <img src={web12} alt=""></img>
                  <div className="overlay1">
                      <div className="row1">
                        <p className="name">WEB DEVELOPMENT</p>
                        <img className="play-button" src={play} alt=""/>
                        <p className="company-name">GREEN DAY</p>
                      </div>
                  </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}

    <div className="button-container content-center">
      <a href="#about-contact"><button className="btn button-touch2"> Explore More
              <span className="line">
                  <span className="circle"></span>
              </span>
      </button></a>
    </div>
    
    {playingVideo && (
      <div className="video-overlay">
        <div className="video-player">
          <ReactPlayer className="player" url={playingVideo} playing={true} controls width="100%" height="100%" />
        </div>
          <img className="close-button1" onClick={() => setPlayingVideo(null)} src={closeButton}/>
        {/* <img className="close-button1" src={closeButton} alt=""/> */}
      </div>
      )}
    {playingImage && (
      <div className="video-overlay">
        <div className="video-player">
          <FullScreenImage src={playingImage} />
      </div>
        <img className="close-button1" onClick={() => setPlayingImage(null)} src={closeButton}/>
      </div>
    )}
  </section>
  )
}