import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import "./CaseStudy.css"


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function CaseStudy() {
    return (
        <section className="caseStudy container">
            <h1 className="commonHeading">Our <span>Case Studies</span></h1>
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 50,
                modifier: 1,
                slideShadows: false, 
              }}
              pagination={{ el: '.swiper-pagination', clickable: true }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="swiper_container"
            >
                <SwiperSlide>
                    <div className='flexSB'>
                        <div className='left'>
                            <div className="youtube-box">
                                <ReactPlayer url="https://youtu.be/iDlKd1AwDYE" className="video"/>
                            </div>
                        </div>
                        <div className='right'>
                            <div className="aboutHeading">
                                <h2>SNAPCHAT</h2>
                                {/* <h2>DAHUA TECHNOLOGIES</h2> */}
                                <p>We collaborated with Snapchat to produce a launch 
                                    video for their partnership with Erada in Qatar. 
                                    Through expert animated transitions, precise audio cues, 
                                    and advanced video post-production techniques, we delivered a 
                                    dynamic and engaging final product that resonated with the audience.</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className='flexSB'>
                    <div className='left'>
                    <div className="youtube-box">
                            <ReactPlayer url="https://youtu.be/bRbkI0eJ24M" className="video"/>
                        </div>
                    </div>
                    <div className='right'>
                    <div className="aboutHeading">
                            <h2>YAMAHA</h2>
                            {/* <h2>DAHUA TECHNOLOGIES</h2> */}
                            <p>For Yamaha Music’s new headset launch, we brought an 
                                innovative vision to life. Our expert scene writers and 
                                directors worked alongside models and actors to create a visually 
                                compelling ad campaign, perfectly aligned with Yamaha’s artistic standards.</p>
                        </div>
                    </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className='flexSB'>
                <div className='left'>
                    <div className="youtube-box">
                        <ReactPlayer url="https://youtu.be/sp6vU1az9gQ" className="video"/>
                    </div>
                </div>
                <div className='right'>
                    <div className="aboutHeading">
                        <h2>NEOM</h2>
                        {/* <h2>DAHUA TECHNOLOGIES</h2> */}
                        <p>We developed 3D isometric animations to visually depict Neom’s 
                            cutting-edge infrastructure and showcase its facilities. 
                            These animations were transformed into video brochures, providing 
                            an immersive experience tailored for Neom's high-end clientele, 
                            perfectly aligning with the brand's futuristic vision.</p>
                    </div>
                </div>
            </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className='flexSB'>
                <div className='left'>
                <div className="youtube-box">
                        <ReactPlayer url="https://youtu.be/beYP-UlZod0" className="video"/>
                    </div>
                </div>
                <div className='right'>
                <div className="aboutHeading">
                        <h2>Australia Post</h2>
                        <p>We handled the entire creative process for Australia Post’s 2D corporate video, from character design to script development. Through engaging animation and seamless storytelling, we delivered a visually compelling narrative that conveyed their message in a polished and professional format, perfectly suited for corporate communication.</p>
                    </div>
                </div>
            </div>
                </SwiperSlide>
                <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-button-next slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            
            </Swiper>
        </section>
    )
}