import React, { useEffect, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion"
import "./Hero.css";

import video from "../../../Assets/Logo/RNC-Cover-Video.mp4";
import whatsappIcon from "../../../Assets/Social-Links/whatsapp.png";
import mouse from "../../../Assets/Common/mouse-white.png"

export default function Hero({ size }) {
  const textVariants = {
    initial: {
      x: -size*5,
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1
      }
    },
    scrollButtonInitial: {
      opacity: 0
    },
    scrollButton: {
      opacity: 1,
      y: 10,
      transition: {
        duration: 2,
        repeat: Infinity
      }
    }
  } 

  const ref = useRef()
  const {scrollYProgress} = useScroll({
    target: ref,
    start: ["start start", "end start"]
  })

  const yBg = useTransform(scrollYProgress, [0, 1], [0, 1000])

  useEffect(() => {
    // if(!closePopup) {
      // Dynamically add the chat script to the DOM inside a custom div
      const chatWidgetContainer = document.createElement("div");
      chatWidgetContainer.id = "chat-widget-container"; // Custom div for styling

      const script = document.createElement("script");
      script.id = "chatway";
      script.src = "https://cdn.chatway.app/widget.js?id=ZEX9e296yx5j";
      script.async = true;

      chatWidgetContainer.appendChild(script);
      document.body.appendChild(chatWidgetContainer);

      return () => {
      // Cleanup: Remove the script and container when the component is unmounted
      if (chatWidgetContainer) {
        document.body.removeChild(chatWidgetContainer);
      }
      };
    // }
  }, []);

  return (
    <div className="hero">
      {/* <div className="overlay"></div> */}
      <video src={video} autoPlay loop muted />
      <motion.div className="hero-content container1" variants = {textVariants} initial="initial" whileInView="animate" style={{ y:yBg}}> {/* Container for overlayed content */}
        {/* <About /> */}
        <motion.h1 variants = {textVariants}>WE CREATE VIDEOS THAT <span className="color">SPARK MOMENTS</span> OF INSIGHT</motion.h1>
        <a href="#about-contact"><motion.button variants = {textVariants} className="btn button-touch2">Let's Discuss
            <span className="line">
                <span className="circle"></span>
            </span>
        </motion.button></a>
      </motion.div>
      <motion.div className="scroll" variants = {textVariants} initial="scrollButtonInitial" animate="scrollButton">
        <img className="mouse" src={mouse} alt="" />
        <a href="#about-contact"><h1>Scroll to explore</h1></a>
      </motion.div>
      <a href="https://wa.me/+971542226464">
        <img src={whatsappIcon} alt="" className="whatsapp-icon" />
      </a>
    </div>
  );
}