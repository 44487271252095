import React, { useEffect, useRef, useState } from 'react'
import "./UseCases.css"
import { AnimatePresence, motion } from "framer-motion"

import image1 from "../../../Assets/Works/video/1.webp"
import image2 from "../../../Assets/Works/video/2.jpg"
import image3 from "../../../Assets/Works/video/3.webp"
import image4 from "../../../Assets/Works/video/4.webp"
import image5 from "../../../Assets/Works/video/5.webp"
import image6 from "../../../Assets/Works/video/6.webp"

import video1 from "../../../Assets/UseCases/Culture.mp4";
import video2 from "../../../Assets/UseCases/Social-Ad.mp4";
import video3 from "../../../Assets/UseCases/Email-Marketing.mp4" 
import video4 from "../../../Assets/UseCases/1.mp4"
import video5 from "../../../Assets/UseCases/2.mp4"

import play from "../../../Assets/Common/play-button.svg"
import closeButton from "../../../Assets/Common/close-button1.svg"
// import ReactPlayer from 'react-player';

const items = [
    {
        id: 1,
        title: "Culture Video",
        img: image1,
        video: video1,
        description1: "Our culture-focused animation service offers a unique, handcrafted approach to enrich brand storytelling and showcase your brand’s essence in a captivating way.",
        description2: "Using video to promote culture allows Rabbit And Carrot to highlight what makes your brand unique, from its mission to the people behind it. Our videos engage audiences by authentically showcasing your company’s story, fostering stronger connections, and creating a memorable impression that builds trust and loyalty. With creative storytelling and dynamic visuals, we capture the essence of your brand culture, making it relatable and inspiring for your audience."
    },
    {
        id: 2,
        title: "Social Ad Video",
        img: image2,
        video: video2,
        description1: "Our social ad animation service brings a distinctive, handcrafted approach to elevate your brand storytelling and engage your audience like never before.",
        description2: "Our Social Ad Video service at Rabbit And Carrot is designed to captivate your audience on social media platforms, delivering your message with impact and style. We create high-quality, engaging videos tailored for platforms like Instagram, Facebook, and TikTok to increase visibility, drive interaction, and boost conversions. From short, snappy ads to visually compelling storytelling, we focus on maximizing engagement while staying true to your brand’s voice and goals. Let us help you reach your audience with videos that are optimized for social sharing and designed to generate results."

    },
    {   
        id: 3,
        title: "Email Marketing Video",
        img: image3,
        video: video3,
        description1: "Our email marketing video service is designed to boost your brand’s storytelling with a unique, personalized approach that simplifies complex messages and captivates your audience.",
        description2: "Our Email Marketing Video service brings your messages to life, helping you cut through inbox clutter and capture your audience’s attention. We craft personalized, high-quality videos that engage viewers, boost open rates, and drive action. Whether it's showcasing a product, sharing a story, or delivering a company update, our email videos are optimized for quick loading and mobile viewing, ensuring they reach your audience effectively. Transform your email campaigns with compelling videos that make a lasting impression and improve engagement."

    },
    {
        id: 4,
        title: "Home Page Video",
        img: image4,
        video: video4,
        description1: "Our home page animation service is crafted to enhance brand storytelling, delivering a unique, handcrafted experience that captivates visitors and brings your brand to life.",
        description2: ""

    },
    {
        id: 5,
        title: "Landing Page Video",
        img: image5,
        video: video5,
        description1: "Our landing page video animation service is designed to enhance brand storytelling with a distinctive, handcrafted touch that captivates visitors and drives conversions.",
        description2: ""

        
    },
    {
        id: 6,
        title: "Sales Enable Video",
        img: image6,
        video: video1,
        description1: "Our sales enablement animation service is crafted to enhance brand storytelling with a unique, handcrafted approach that empowers your sales team and drives customer engagement.",
        description2: ""
    }
]

export default function UseCases({ size }) {
    const [playingVideo, setPlayingVideo] = useState(null);
    const [currentUseCase, setCurrentUseCase] = useState(items.find(ele => ele.video === playingVideo))

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl)
    };

    console.log(currentUseCase)

    useEffect(() => {
        setCurrentUseCase(items.find(ele => ele.video === playingVideo))
    }, [setCurrentUseCase, playingVideo])

    // function adjustOverlayHeight() {
    //     const images = document.querySelectorAll('.usecases-container .left img', '.usecases-container .right img');
    //     const overlays = document.querySelectorAll('.usecases-container .overlay', '.usecases-container .overlay1');

    //     images.forEach((img, index) => {
    //         const overlay = overlays[index];
    //         if (overlay && img.complete) {
    //         overlay.style.height = `${img.offsetHeight}px`;
    //         }
    //     });
    // }
    
    //   useEffect(() => {
    //     adjustOverlayHeight();
    //     window.addEventListener("resize", adjustOverlayHeight);
    //     return () => window.removeEventListener("resize", adjustOverlayHeight);
    //   }, []);

    return (
        <section id="usecases" className="usecases">
            <h1 className="commonHeading">Use <span className="color">Cases</span></h1>
            <div className="usecases-container">
                <div className="left">
                    <motion.img 
                        initial={{
                            opacity: 0,
                            x: -size
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}
                        src={image1} alt="" 
                        onClick={() => handleVideoClick(items[0].video)}
                        // className="usecases-img"
                    />
                    <div className="overlay">
                        <div className="row">
                            {/* <p className="name">VIDEO PRODUCTION</p> */}
                            <img className="play-button" src={play} alt=""/>
                            {/* <p className="company-name">KNORR</p> */}
                        </div>
                    </div>
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: size
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}>
                        <h1>{items[0].title}</h1>
                        <p>{items[0].description1}</p>
                    </motion.div>
                </div>
                <div className="right">
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: -size
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}>
                        <h1>{items[1].title}</h1>
                        <p>{items[1].description1}</p>
                    </motion.div>
                    <motion.img 
                        initial={{
                            opacity: 0,
                            x: size
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}
                        src={image2} alt=""
                        onClick={() => handleVideoClick(items[1].video)}
                    />
                    <div className="overlay1">
                        <div className="row">
                        {/* <p className="name">VIDEO PRODUCTION</p> */}
                        <img className="play-button" src={play} alt=""/>
                        {/* <p className="company-name">KNORR</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="usecases-container">
                <div className="left">
                    <motion.img 
                        initial={{
                            opacity: 0,
                            x: -size
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}
                        src={image3} alt=""
                        onClick={() => handleVideoClick(items[2].video)} 
                    />
                    <div className="overlay">
                        <div className="row">
                        {/* <p className="name">VIDEO PRODUCTION</p> */}
                        <img className="play-button" src={play} alt=""/>
                        {/* <p className="company-name">KNORR</p> */}
                        </div>
                    </div>
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: size
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}>
                        <h1>{items[2].title}</h1>
                        <p>{items[2].description1}</p>
                    </motion.div>
                </div>
                <div className="right">
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: -size
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}>
                        <h1>{items[3].title}</h1>
                        <p>{items[3].description1}</p>
                    </motion.div>
                    <motion.img 
                        initial={{
                            opacity: 0,
                            x: size
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}
                        src={image4} alt=""
                        onClick={() => handleVideoClick(items[3].video)}
                    />
                    <div className="overlay1">
                        <div className="row">
                        {/* <p className="name">VIDEO PRODUCTION</p> */}
                        <img className="play-button" src={play} alt=""/>
                        {/* <p className="company-name">KNORR</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="usecases-container">
                <div className="left">
                    <motion.img 
                        initial={{
                            opacity: 0,
                            x: -size
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}
                        src={image5} alt=""
                        onClick={() => handleVideoClick(items[4].video)}
                    />
                    <div className="overlay">
                        <div className="row">
                        {/* <p className="name">VIDEO PRODUCTION</p> */}
                        <img className="play-button" src={play} alt=""/>
                        {/* <p className="company-name">KNORR</p> */}
                        </div>
                    </div>
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: size
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}>
                        <h1>{items[4].title}</h1>
                        <p>{items[4].description1}</p>
                    </motion.div>
                </div>
                <div className="right">
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: -size
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}>
                        <h1>{items[5].title}</h1>
                        <p>{items[5].description1}</p>
                    </motion.div>
                    <motion.img 
                        initial={{
                            opacity: 0,
                            x: size
                        }}
                        whileInView={{
                            opacity: 1,
                            x: 0
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeOut",
                            delay: 0.2
                        }}
                        viewport={{ once: false, amount: 0.5 }}
                        src={image6} alt=""
                        onClick={() => handleVideoClick(items[5].video)}
                    />
                    <div className="overlay1">
                        <div className="row">
                        {/* <p className="name">VIDEO PRODUCTION</p> */}
                        <img className="play-button" src={play} alt=""/>
                        {/* <p className="company-name">KNORR</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {playingVideo && (
                    <motion.div 
                        className="video-overlay" 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <motion.div 
                            className="video-player"
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            transition={{ duration: 0.5 }}
                        >
                            <video src={playingVideo} autoPlay loop muted/>
                            <div className="video-content">
                                <h1>{currentUseCase?.title.toUpperCase()}</h1>
                                <h3>WHY USE VIDEO TO PROMOTE {currentUseCase?.title.replace(/\bVideo\b/i, '').trim().toUpperCase()}?</h3>
                                <p>{currentUseCase?.description2}</p>
                            </div>
                        </motion.div>
                        <motion.img 
                            animate={{
                                scale: [1, 1.2, 1.2, 1],
                                rotate: [0, 90, 90, 0],
                                borderRadius: [ "50%"]
                            }}
                            transition={{
                                duration: 5,
                                ease: "easeInOut",
                                repeat: Infinity,
                                repeatDelay: 1
                            }}
                            whileTap={{ scale: 0.9}}
                            whileHover={{
                                scale: 1.2,
                                background: "#BE3030",
                                color: "white"
                            }}
                            drag
                            dragConstraints={{
                                top: -60,
                                right: 60,
                                bottom: 0,
                                left: -60
                            }}
                            className="close-button1" onClick={() => setPlayingVideo(null)} src={closeButton}/>
                    </motion.div>
                )}
            </AnimatePresence>

        </section>
    )
}