import React, { useState } from 'react';
import './PopupForm.css';
// import logo from "../../../Assets/Logo/RNC-Logo-black.png"
import closeButton from "../../../Assets/Common/close.png"
import fliplogo from "../../../Assets/Common/fliplogo.gif"

// const access_key = "f65ef0eb-57aa-45ba-91f7-aaf00dfe46ee" // Sinan
const access_key = "bcad8f77-6513-4b70-badc-2db336b225d3" // Sadik

function PopupForm({ handleClose }) {
  const [result, setResult] = useState("");
  const [otherOption, setOtherOption] = useState(false)

  const handleServiceChange = (event) => {
    setOtherOption(event.target.value === "Other")
  }
  // const [isFormOpen, setIsFormOpen] = useState(true)
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     services: ""
//   });

//   const handleChange = (e) => {
//     setFormData({...formData, [e.target.name]: e.target.value})
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     console.log(formData)
//     await sendToZoho(formData);
//   }

//   const sendToZoho = async (data) => {
//     const response = await fetch('https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.leads.CREATE&client_id={1000.3U9TVE6U969O97CWXWT3KM4LRJQBFU}&response_type=code&access_type={%22online%22}&redirect_uri={https://rabbitandcarrot.com/videoproduction}', {
//         method: 'POST',
//         headers: {
//             'Authorization': `Zoho-oauthtoken 1000.8a7aa3143ce1728469c80e6e09a3a35e.0b839adfb8ba3c280d09d2b3f6421321`, // replace with your token
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//             data: [
//                 {
//                     Last_Name: data.name,
//                     Email: data.email,
//                     Phone: data.phone,
//                 },
//             ],
//         }),
//     });

//     if (response.ok) {
//         console.log('Data successfully sent to Zoho CRM');
//     } else {
//         console.error('Failed to send data to Zoho CRM');
//     }
// };

 
  const handleSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);

      formData.append("access_key", access_key);

      const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
      });

      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
        // Redirect to the URL with UTM parameters after form submission
        window.location.href = "https://videoproduction.rabbitandcarrot.com/?utm_source=Pop-Up+Form&utm_medium=RNC_Campaign&utm_campaign=Submit+Button&utm_id=SubDomain";
        handleClose()
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
  };
  return (
    <div className="modal-overlay">
      <div className={`modal-content ${ otherOption ? "height" : ""}`}>
        <img src={closeButton} alt="" className="closeButton" onClick={() => { handleClose() }}/>
        <div className="left">
            {/* <h1>rabbotandcarrot</h1> */}
            <img src={fliplogo} alt="" />
            {/* <p>We’re all about creating communications that sparkle and 
              shine for the brands we love. 
              But there’s more to us than just creating pretty pictures.
            </p> */}
        </div>
        <div className="right">
          <div className="contact-col">
            <h1> <span className="color">No Time, No Problem!</span><br/>Share your details to connect and discuss.</h1>
            <form onSubmit={handleSubmit}>
                {/* <label>Your Name</label> */}
                <input type="text" name="name" placeholder="Enter Your Name" required/>
                {/* <label>Phone Number</label> */}
                <input type="tel" name="phone" placeholder="Enter Your Mobile" required/>
                {/* <label>Email</label> */}
                <input type="email" name="email" placeholder="Enter Your Email" required/>
                <select name="services" onChange={handleServiceChange} required>
                  <option value="">Services</option>
                  <option value="2D Animaton">2D & 3D Animaton</option>
                  <option value="Video Producton">Video Production</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Web Development">Web Development</option>
                  <option value="Other">Other</option>
                </select>
                { otherOption && <input type="text" name="Other Services" placeholder="Enter Your Sericves" required/>}
                <button className="btn button-touch3">Submit Now
                        <span className="line">
                            <span className="circle"></span>
                        </span>
                </button>
            </form>
            <span className="result">{result}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupForm;


// {/* <div className="modal-overlay">
//       <div className="modal-content">
//         <h2>But first, Cookies<img src={cookies} alt=""/></h2>
//         <p>
//         We use essential cookies to make rabbitandcarrot work. We'd like to use other cookies to improve and personalise your visit, 
//         tailor ads you see from us on rabbitandcarrot, and to analyse our website's performance, but only if you accept. 
//         Learn more about your choices in our website, rabbbitandcarrot Information LLC.
//         </p>
//         <div className="modal-buttons">
//           <button onClick={onAccept} className="accept-button">Accept all cookies</button>
//           <button onClick={onDecline} className="decline-button">Decline</button>
//         </div>
//       </div>
//     </div> */}