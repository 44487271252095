import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// import { Swiper, SwiperSlide } from 'swiper/react';
// import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import img1 from "../../../Assets/GetInTouch/2d.png"
import image from "../../../Assets/Common/icon.png"
import image2 from "../../../Assets/Common/icon.webp"
// import arrow from "../../../Assets/Common/dark-arrow.png" 
import phoneIcon from "../../../Assets/Common/phone-white.svg"
import chatIcon from "../../../Assets/Common/chat-white.svg"

import bg1 from "../../../Assets/Bg/2d1.png"
import bg2 from "../../../Assets/Bg/1.jpg"
import bg3 from "../../../Assets/Bg/brand1.jpg"
import bg4 from "../../../Assets/Bg/digital1.webp"
import bg5 from "../../../Assets/Bg/web.png"

import "./GetInTouch.css"

const services = [
    {
        id: 1,
        activeSection: "video-production",
        title: "With Innovating Video Production",
        description: "At Rabbit And Carrot, our creative team understands the impact of compelling video production in today's digital world. We are dedicated to delivering exceptional video solutions that bring our client's visions to life. Through close collaboration, we gain a deep understanding of each client's unique needs, ensuring high-quality results that elevate their brand and drive business success.",
        img: image2,
        bg: bg2,
        className: "get-in-touch-container-video"
    },
    {
        id: 2,
        activeSection: "2d&3d-animation",
        title: "With Inspiring 2D & 3D Video Animations",
        description: "At Rabbit And Carrot, our creative team knows the power of captivating animations in today's digital landscape. We deliver premium 2D and 3D animation solutions that bring your brand's vision to life. By working closely with our clients, we gain a deep understanding of their goals, ensuring impactful, high-quality animations that meet their needs and elevate their brand presence.",
        img: img1,
        bg: bg1,
        className: "get-in-touch-container-2d3d"
    },
    {
        id: 3,
        activeSection: "branding",
        title: "With Creative Brand Design",
        description: "At Rabbit And Carrot, our creative team recognizes the importance of a strong brand identity in the digital landscape. We are committed to crafting premium branding solutions that capture the essence of each client's vision. By working closely with our clients, we gain valuable insights into their unique needs, delivering impactful results that strengthen their brand presence and drive business growth.",
        img: image,
        bg: bg3,
        className: "get-in-touch-container-brand"
    },
    {
        id: 4,
        activeSection: "digital-marketing",
        title: "With Impactful Digital Marketing",
        description: "At Rabbit And Carrot, our creative team understands the power of a strong digital presence in today’s competitive landscape. We are dedicated to delivering top-tier digital marketing solutions tailored to our clients' unique needs. Through close collaboration, we gain deep insights into their goals, allowing us to create strategies that drive meaningful results and elevate their business success.",
        img: image2,
        bg: bg4,
        className: "get-in-touch-container-digital"
    },
    {
        id: 5,
        activeSection: "web-development",
        title: "With Inspiring Web Development",
        description: "At Rabbit And Carrot, our creative team knows the importance of a powerful online presence in today’s digital world. We deliver exceptional web development solutions that align perfectly with our clients’ needs. By working closely with each client, we gain valuable insights into their goals, enabling us to create high-quality, impactful websites that enhance their brand and drive business growth.",
        img: image,
        bg: bg5,
        className: "get-in-touch-container-web"
    }
  ];

export default function GetInTouch({activeSection, handleSectionChange, size}) {
    const textVariants = {
        initial: { x: size, opacity: 0 },
        animate: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
        exit: { x: size, opacity: 0, transition: { duration: .5 } },
        initial1: { x: -size, opacity: 0 },
        animate1: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
        exit1: { x: -size, opacity: 0, transition: { duration: .5 } },
    };
    const [currentSlide, setCurrentSlide] = useState(services.find(ele => ele.activeSection === activeSection));

    useEffect(()=> {
        setCurrentSlide(services.find(ele => ele.activeSection === activeSection));
    }, [activeSection])
    

    // const handleDotClick = (ele) => {
    //     setCurrentSlide(ele);
    //   };

    return (
        <section className='get-in-touch'>
            <div className={`get-in-touch-container container1`}>
            <AnimatePresence mode="wait">
                <motion.div 
                    key={currentSlide.id} // Changes on each slide change
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: .5 }} // Smooth fade effect
                    className="background-image"
                    style={{ backgroundImage: `url(${currentSlide.bg})` }}
                />
            </AnimatePresence>
                <div className="overlay2"></div>
                <div className='flexSB5'>
                    <AnimatePresence mode="wait">
                        <motion.div 
                            key={currentSlide.id} // Ensures the entire text section changes per slide
                            initial="initial"
                            whileInView="animate"
                            transition={{
                                duration: 1,
                                ease: "easeOut",
                                delay: 0.2
                            }}
                            exit="exit"
                            viewport={{ once: false, amount: 0.5 }}
                            variants={textVariants}
                            className='right'>
                            <div className="get-in-touch-details">
                                <h3>Empower Your Brand</h3>
                                <h2>{currentSlide.title}</h2>
                                <p>{currentSlide.description}</p>
                                <div className="contact-details">
                                    <div className="contact-phone">
                                        <img src={phoneIcon} alt="" className="phoneIcon"/>
                                        <a href="tel:+971542226464">
                                            <span>CALL AT US</span><br/>
                                            +971 542226464
                                        </a>
                                    </div>
                                    <div>
                                        <a href="#about-contact">
                                            <button className="btn button-touch2">Get a Quote
                                                <span className="line">
                                                    <span className="circle"></span>
                                                </span>
                                            </button>
                                        </a>
                                    </div>
                                    <div className="contact-chat">
                                        <img src={chatIcon} alt="" className="chatIcon"/>
                                        <a href="#about-contact">
                                            <span>CLICK HERE FOR</span><br/>
                                            LIVE CHAT
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </motion.div>
                    </AnimatePresence>
                </div>
                <div className="dotsContainer">
                    {services.map((ele) => (
                    <div
                        key={ele.id}
                        onClick={() => {
                            handleSectionChange(ele.activeSection)
                            // handleDotClick(ele)
                        }}
                        className={`dot ${currentSlide.id === ele.id ? "dotActive" : "dotInactive"}`}
                    />
                    ))}
                </div>
            </div>
        </section>
    )
}