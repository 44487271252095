import { motion } from "framer-motion"

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import "./Client.css"

import client1 from "../../../Assets/Clients/client1.webp"
import client2 from "../../../Assets/Clients/client2.webp"
import client3 from "../../../Assets/Clients/client3.png"
import client4 from "../../../Assets/Clients/client4.webp"
import client5 from "../../../Assets/Clients/client5.png"
import client6 from "../../../Assets/Clients/client6.png"
import client7 from "../../../Assets/Clients/client7.png"
import client8 from "../../../Assets/Clients/client8.png"
import client9 from "../../../Assets/Clients/client9.png"
import client10 from "../../../Assets/Clients/client10.png"
import client11 from "../../../Assets/Clients/client11.webp"
import client12 from "../../../Assets/Clients/client12.png"
import client13 from "../../../Assets/Clients/client13.png"
import client14 from "../../../Assets/Clients/client14.webp"
import client15 from "../../../Assets/Clients/client15.webp"
import client16 from "../../../Assets/Clients/client16.png"

const gridContainerVariants = {
  hidden: {
      opacity: 0
  },
  show: {
      opacity: 1,
      transition: {
          staggerChildren: .2,
      }
  }
}

const gridSquareVariants = {
  hidden: {
      opacity: 0,
      y: 20,
  },
  show: {
      opacity: 1,
      transition: {
          y: 0,
          duration: 0.5,
      }
  }
}

function Client() {
    // function SampleNextArrow(props) {
    //     const { className, onClick } = props;
    //         return (
    //             <div
    //                 className={`${className} custom-arrow next-arrow`} 
    //                 // style={{ ...style, display: "block", background: "#BE3030" }}
    //                 onClick={onClick}
    //             />
    //         );
    // }
        
    // function SamplePrevArrow(props) {
    //     const { className, onClick } = props;
    //         return (
    //             <div
    //                 className={`${className} custom-arrow prev-arrow`}
    //                 // style={{ ...style, display: "block", background: "#BE3030" }}
    //                 onClick={onClick}
    //             />
    //         );
    // }
    // const settings = {
    //     dots: true,
    //     infinite: false,
    //     // speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 2,
    //     initialSlide: 0,
    //     autoplay: true,
    //     speed: 2000,
    //     autoplaySpeed: 2000,
    //     cssEase: "linear",
    //     pauseOnHover: true,
    //     nextArrow: <SampleNextArrow />,
    //     prevArrow: <SamplePrevArrow />,
    //     responsive: [
    //         {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 3,
    //             slidesToScroll: 3,
    //             infinite: true,
    //             dots: true
    //         }
    //         },
    //         {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 2,
    //             slidesToScroll: 2,
    //             initialSlide: 2
    //         }
    //         },
    //         {
    //         breakpoint: 480,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1,
    //             dots: false
    //         }
    //         }
    //     ]
    // };
    return (
      <section className="container1 client">
          <h1 className="commonHeading">Our <span>Clients</span></h1>
              <motion.div 
                variants={gridContainerVariants}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.5 }}
                className="client-body">
                  {/* <Slider {...settings}> */}
                      {data1.map((d, i) => (
                          <motion.div 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            key={d.img} 
                            className="client-image">
                              <img src={d.img} alt="" className=""/>
                          </motion.div>
                      ))} 
                  {/* </Slider> */}
              </motion.div>
      </section>
    );
}

const data1 = [
  {
    img: client1,
  },
  {
    img: client2,
  },
  {
    img: client3,
  },
  {
    img: client4,
  },
  {
    img: client5,
  },
  {
    img: client6,
  },
  {
    img: client7,
  },
  {
    img: client8,
  },
  {
    img: client9,
  },
  {
    img: client10,
  },
  {
    img: client11,
  },
  {
    img: client12,
  },
  {
    img: client13,
  },
  {
    img: client14,
  },
  {
    img: client15,
  },
  {
    img: client16,
  },
];

export default Client;