import React, { useRef } from 'react'
import ReactIframe from 'react-iframe'
import {motion, useScroll, useSpring, useTransform} from "framer-motion"
import "./AboutUs.css"

function About({ size }) {
  const textVariants = {
    initial: {
      x: -size,
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1
      }
    },
    scrollButtonInitial: {
      opacity: 0
    },
    scrollButton: {
      opacity: 1,
      y: 10,
      transition: {
        duration: 2,
        repeat: Infinity
      }
    }
  } 
    const ref = useRef()
    const {scrollYProgress} = useScroll({
        target: ref,
        start: ["start start", "end start"]
    })

  const yBg = useTransform(scrollYProgress, [0, 1], [-500, 500])
  return (
        <section id="about-contact" className='aboutHome container1' ref={ref} >
            <motion.div className='flexSB' style={{yBg}}>
                <div className='left'>
                    <motion.div 
                        initial="initial"
                        whileInView="animate"
                        transition={{
                          duration: 1,
                          ease: "easeOut",
                          delay: 0.2
                        }}
                        exit="exit"
                        viewport={{ once: false, amount: 0.5 }}
                        variants={textVariants}
                        className="aboutHeading">
                        <motion.h1>About Us</motion.h1>
                        <motion.h3>Crafting Digital Brilliance</motion.h3>
                        <motion.h2>BRINGING DIGITAL STORYTELLING TO LIFE THROUGH VISUALS</motion.h2>
                        <motion.p>At Rabbit And Carrot, we have mastered the art of storytelling and digital innovation, 
                          reshaping perspectives since 2008-a long time in internet years! Our focus isn't just on aesthetics; 
                          we create impactful communications that deliver measurable results. We believe in the transformative power of storytelling through video, 
                          where our unique approach turns your ideas into captivating narratives that deeply resonate with your audience. 
                          Whether it is a corporate video, promotional content, or a moving brand story, we craft narratives that go beyond informing-they inspire. 
                          Every project is uniquely designed to showcase your brand's distinct voice, ensuring your message cuts through the noise.</motion.p>
                    </motion.div>
                    {/* <div className='items'>
                            <h2>Creative Mastery</h2>
                            <h2>Strategic Impact</h2>
                            <h2>Innovative Solutions</h2>
                    </div> */}
                </div>
                <div className="right">
                        <ReactIframe
                            aria-label='Get A Quote' 
                            frameborder="0"
                            style={{ padding: '20px', border: 'none' }}
                            src='https://forms.zohopublic.com/virtualoffice10491/form/GetAQuote/formperma/lIfmVFP1bp-fpxo8a-1RQEeRi2qrVOvb8-j3CFbWkx0' 
                            width="100%" 
                            height="536.5px"
                        />
                </div>
                </motion.div>
        </section>
  )
}

export default About