import React, { useEffect, useState } from 'react'
import "./Navbar.css"

import logo_white from "../../../Assets/Logo/RNC-Logo-white.png"
import logo_black from "../../../Assets/Logo/RNC-Logo-black.png"
// import logo_black from "../../../Assets/Logo/RNC-Logo-black.png"

// import menuIcon from "../../../Assets/Common/menu-icon.svg"
import menuIconRed from "../../../Assets/Common/menu-icon-red.svg"
import close from "../../../Assets/Common/close-red.svg"
import flag from "../../../Assets/Common/UAEflag.png"
import mail_icon from "../../../Assets/Common/mail.svg"
import phone_icon from "../../../Assets/Common/phone.svg"
import { IoMdArrowDropdown } from "react-icons/io";

import { useLocation } from 'react-router-dom'

export default function Navbar({ handleSectionChange, activeSection }){
  const location = useLocation()
  const [mobileMenu, setMobileMenu] = useState(false)
  const [sticky, setSticky] = useState(false)
  // const [activeSection, setActiveSection] = useState('2d&3d-animation');

  const toggle = () => {
    setMobileMenu(!mobileMenu)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 100 ? setSticky(true) : setSticky(false)
    })
  }, [])

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  return (
    <div className="navbar">
      <nav className={`container ${sticky? "dark-nav":""}`}>
      <a href="/"><img src={sticky ? logo_black : logo_white} alt="" className={sticky ? "black-logo" : "logo"}></img></a>
      <ul className={mobileMenu ? "navbar-ul" : "hide-mobile-menu"}>
        <li><a href="#about-contact" className={`${location.hash === "#about-contact" ? "active" : ""}`}>ABOUT</a></li>
        <li
          className="navbar-item dropdown"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <a href={`#services#${activeSection}`} className={`${location.hash === `#services#${activeSection}` ? "active" : ""}`}>
            SERVICES<IoMdArrowDropdown className="dropdown-icon"/>
          </a>
          {isDropdownOpen && (
            <ul className={mobileMenu ? "dropdown-menu-mobile" : "dropdown-menu"}>
              <li className="dropdown-item">
                <a
                  href="#services#2d&3d-animation"
                  onClick={() => { handleSectionChange("2d&3d-animation") }}
                  className={`${location.hash === `#services#2d&3d-animation` ? "active" : ""}`}
                >
                  2D & 3D ANIMATION
                </a>
              </li>
              <li className="dropdown-item">
                <a
                  href="#services#video-production"
                  onClick={() => { handleSectionChange("video-production"); }}
                  className={`${activeSection === `video-production` ? "active" : ""}`}
                >
                  VIDEO PRODUCTION
                </a>
              </li>
              <li className="dropdown-item">
                <a
                  href="#services#branding"
                  onClick={() => { handleSectionChange("branding"); }}
                  className={`${activeSection === `branding` ? "active" : ""}`}
                >
                  BRANDING
                </a>
              </li>
              <li className="dropdown-item">
                <a
                  href="#services#digital-marketing"
                  onClick={() => { handleSectionChange("digital-marketing"); }}
                  className={`${activeSection === `digital-marketing` ? "active" : ""}`}
                >
                  DIGITAL MARKETING
                </a>
              </li>
              <li className="dropdown-item">
                <a
                  href="#services#web-development"
                  onClick={() => { handleSectionChange("web-development"); }}
                  className={`${activeSection === `web-development` ? "active" : ""}`}
                >
                  WEB DEVELOPMENT
                </a>
              </li>
            </ul>
          )}
        </li>
        <li><a href="#portfolio" className={`${location.hash === "#portfolio" ? "active" : ""}`}>PORTIFOLIO</a></li>
        <li><a href="#usecases" className={`${location.hash === "#usecases" ? "active" : ""}`}>USE CASES</a></li>
        <li><a href="#testimonials" className={`${location.hash === "#testimonials" ? "active" : ""}`}>TESTIMONIALS</a></li>
        {/* Add flag, phone number, and button for mobile view */}
        <a href="#about-contact" className="btn-mobile">Get in Touch</a>
        <div className="mail-phone-mobile">
          <img src={logo_black} alt="" className="side-logo"></img>
          <p>
            We’re all about creating communications that sparkle and shine for the brands we love. 
            But there’s more to us than just creating pretty pictures.
          </p>
          <a href="mailto:info@rabbitandcarrot.com" className="mail-mobile"><li><img className="mail-icon" src={mail_icon} alt=""/>info@rabbitandcarrot.com<br/></li></a>
          <a href="tel:+971542226464" className="phone-mobile"><li><img className="phone-icon" src={phone_icon} alt=""/>+971 54 222 6464</li></a>
        </div>
      </ul>
      <div className="flag-number">
        <img src={flag} alt="" className="flag"/><a href="tel:+971542226464" className="phone-number">+971 54 222 6464</a>
      </div>
      <button className={`btn1 ${sticky? "btn2" :""}`}><a href="#about-contact">Get in Touch</a></button>
      <img src={ mobileMenu ? close : menuIconRed} alt="" className="menuIcon" onClick={() => toggle()}/>
    </nav>
    </div>
  )
}