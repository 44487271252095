import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './Components/Common/Navbar/Navbar';
import Navbar1 from "./Pages/Navbar1";
import Footer from './Components/Common/Footer/Footer';
import Footer1 from "./Pages/Footer1";
import Thankyou from './Pages/Thankyou';
import PopupForm from './Components/Common/PopupForm/PopupForm';
import PageNotFound from './Components/Common/PageNotFound/PageNotFound';
// import ZohoForm from './Components/Common/PopupForm/ZohoForm';

// import AOS from "aos";
// import "aos/dist/aos.css";

function App() {
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [size, setSize] = useState(0); // Default size based on initial width
  const [duration, setDuration] = useState(.5)

  useEffect(() => {
      // Function to handle resize and set screen width and size accordingly
      const handleResize = () => {
          const width = window.innerWidth;
          // setScreenWidth(width);
          if (width < 500) {
            setSize(0);
            setDuration(.2)
          } else if (width < 700) {
            setSize(10);
            setDuration(.3)
          } else if (width < 1200) {
            setSize(20);
            setDuration(.4)
          } else {
            setSize(50); // Default size for widths above 1200
            setDuration(.5)
          }
      };
      
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
      
      // Initial check in case window width has already changed
      handleResize();
      
      // Clean up event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  // useEffect(() => {
  //   AOS.init({
  //     offset: 100,
  //     duration: 700,
  //     easing: "ease-in",
  //     delay: 100,
  //   });
  //   AOS.refresh();
  // }, []);
  
  const location = useLocation();
  const [closePopup, setClosePopup] = useState(false);
  const [formAppear, setFormAppear] = useState(false);

  // Function to handle closing the popup and storing flag in localStorage
  const handleClose = () => {
    setClosePopup(true);
    localStorage.setItem('formAppear', 'true'); // Set flag so popup doesn't reappear
  };

  // Check localStorage on initial load
  useEffect(() => {
    const formAppearFlag = localStorage.getItem('formAppear');
    if (formAppearFlag === 'true') {
      setFormAppear(false); // Popup should not appear if previously closed
    } else {
      setFormAppear(true); // Show popup if it's the first visit
    }
  }, []);

  const [activeSection, setActiveSection] = useState('video-production');

  const handleSectionChange = (section) => {
    setActiveSection(section);
  }

  // console.log(activeSection)

  return (
    <Fragment>
      {location.pathname === "/" ? <Navbar handleSectionChange={handleSectionChange} activeSection={activeSection}/> : <Navbar1 />}
      <Routes>
        {/* <Route path="/" element={<Navigate to="/" />} /> */}
        <Route path="/" element={<Home handleSectionChange={handleSectionChange} activeSection={activeSection} size={size} duration={duration} />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {location.pathname === "/" ? <Footer /> : <Footer1 />}
      {/* {<ZohoForm/> } */}
      {location.pathname === "/" && !closePopup && formAppear && <PopupForm handleClose={handleClose} />}
    </Fragment>
  );
}

export default App;


// useEffect(() => {
//   const consent = localStorage.getItem('userConsent');
//   if (consent === 'accepted') {
//     setHasConsent(true);
//   }
// }, []);

// hasConsent = {hasConsent}
// const [hasConsent, setHasConsent] = useState(false);
// const handleAccept = () => {
//   localStorage.setItem('userConsent', 'accepted');
//   setHasConsent(true);
// };

// const handleDecline = () => {
//   localStorage.setItem('userConsent', 'declined');
  
//   // Try to close the current window
//   if (window.opener) {
//     window.close(); // Will work if the window was opened via a script
//   } else {
//     // Force close by opening and immediately closing a new window
//     window.open('about:blank', '_self').close();
//   }
// };

// {/* If consent hasn't been given, show the consent modal */}
// {!hasConsent && location.pathname === "/videoproduction" && <ConsentModal onAccept={handleAccept} onDecline={handleDecline} />}