import AboutUs from "../Components/Common/AboutUs/AboutUs"; 
import Client from "../Components/Common/Clients/Clients";
import Hero from "../Components/Common/Hero/Hero";
import Services from "../Components/Common/Sevices/Services";
import Testimonials from "../Components/Common/Testimonials/Testimonials";
import UseCases from "../Components/Common/UseCases/UseCases";
import Gallary from "../Components/Common/Gallary/Gallary";
import GetInTouch from "../Components/Common/GetInTouch/GetInTouch";
import CaseStudy from "../Components/Common/CaseStudy/CaseStudy";
import QuickContact from "../Components/Common/QuickContact/QuickContact"
import Helmet from "../Components/Common/Helmet/Helmet";
// import CardsContainer from "../Components/Common/Sevices/CardsContainer";

import { useEffect } from "react";

export default function Home({ activeSection, handleSectionChange, size, duration }) {
    useEffect(() => {
        // Load Facebook Pixel script
        /* eslint-disable */
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        /* eslint-enable */
    
        // Initialize Facebook Pixel
        window.fbq('init', '1188797378845533');
        window.fbq('track', 'PageView');

        // Load Google Analytics scripts
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = "https://www.googletagmanager.com/gtag/js?id=G-QVHBEMW15X";
        document.head.appendChild(script1);

        const script2 = document.createElement('script');
        script2.async = true;
        script2.src = "https://www.googletagmanager.com/gtag/js?id=G-SN7K78KPND";
        document.head.appendChild(script2);

        script1.onload = script2.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            gtag('js', new Date());

            // Configure each Google Analytics tag ID
            gtag('config', 'G-QVHBEMW15X');
            gtag('config', 'G-SN7K78KPND');
        };

        // Cleanup function to remove scripts on unmount
        return () => {
            document.head.removeChild(script1);
            document.head.removeChild(script2);
        };
    }, []); // Empty dependency array to load only when Home mounts

    return (
        <section>
            <Helmet title="Video Production">
                {/* NoScript for Pixel Tracking */}
                <noscript>
                    <img
                    height="1"
                    width="1"
                    style={{ display: 'none' }}
                    src="https://www.facebook.com/tr?id=1188797378845533&ev=PageView&noscript=1"
                    alt="facebook-pixel"
                    />
                </noscript>
                <Hero size={size}/>
                <AboutUs size={size}/>
                <Services handleSectionChange={handleSectionChange} activeSection={activeSection} size={size} duration={duration}/>
                <GetInTouch handleSectionChange={handleSectionChange} activeSection={activeSection} size={size}/>
                <Gallary handleSectionChange={handleSectionChange} activeSection={activeSection} size={size}/>
                <UseCases size={size}/>
                <CaseStudy/>
                <QuickContact/>
                <Client/>
                <Testimonials/>
            </Helmet>
        </section>
    );
}