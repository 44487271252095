import React from 'react'
import { Row } from "reactstrap"

import {motion} from "framer-motion"
// import { fadeIn } from '../../../variants'

import "./Services.css"

import twoD_1 from "../../../Assets/Services2/1/1.svg"
import twoD_2 from "../../../Assets/Services2/1/2.svg"
import twoD_3 from "../../../Assets/Services2/1/3.svg"
import twoD_4 from "../../../Assets/Services2/1/4.svg"
import twoD_5 from "../../../Assets/Services2/1/5.svg"
import twoD_6 from "../../../Assets/Services2/1/6.svg"
import twoD_7 from "../../../Assets/Services2/1/7.svg"
import twoD_8 from "../../../Assets/Services2/1/8.svg"

import video_1 from "../../../Assets/Services2/2/1.svg"
import video_2 from "../../../Assets/Services2/2/2.svg"
import video_3 from "../../../Assets/Services2/2/3.svg"
import video_4 from "../../../Assets/Services2/2/4.svg"

import digital_1 from "../../../Assets/Services2/3/1.svg"
import digital_2 from "../../../Assets/Services2/3/2.svg"
import digital_3 from "../../../Assets/Services2/3/3.svg"
import digital_4 from "../../../Assets/Services2/3/4.svg"

import branding_1 from "../../../Assets/Services2/4/1.svg"
import branding_2 from "../../../Assets/Services2/4/2.svg"
import branding_3 from "../../../Assets/Services2/4/3.svg"
import branding_4 from "../../../Assets/Services2/4/4.svg"
import branding_5 from "../../../Assets/Services2/4/5.svg"
import branding_6 from "../../../Assets/Services2/4/6.svg"
import branding_7 from "../../../Assets/Services2/4/7.svg"
import branding_8 from "../../../Assets/Services2/4/8.svg"

import web_1 from "../../../Assets/Services2/5/1.svg"
import web_2 from "../../../Assets/Services2/5/2.svg"
import web_3 from "../../../Assets/Services2/5/3.svg"
import web_4 from "../../../Assets/Services2/5/4.svg"
import web_5 from "../../../Assets/Services2/5/5.svg"
import web_6 from "../../../Assets/Services2/5/6.svg"
import web_7 from "../../../Assets/Services2/5/7.svg"
import web_8 from "../../../Assets/Services2/5/8.svg"


import new1 from "../../../Assets/Services-new/2d&3d/1.jpg"

// import ReactPlayer from 'react-player'

// import image from "../../../Assets/Hero/image2.jpg"
// import animation from "../../../Assets/Home/animation-video.webp"
// import marketing from "../../../Assets/Home/digital-marketing.png"
// import brand from "../../../Assets/Home/brand-development.webp"
// import web from "../../../Assets/Home/web-development.png"

function Services({activeSection, handleSectionChange, size, duration}) {
    const gridContainerVariants = {
        hidden: {
            opacity: 0
        },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: .2,
            }
        }
    }
    
    const gridSquareVariants = {
        hidden: {
            opacity: 0,
            y: 20,
        },
        show: {
            opacity: 1,
            transition: {
                y: 0,
                duration: .5,
            }
        }
    }
  return (
    <section id={`services#${activeSection}`} className="services container1">
        <h1 className="commonHeading">Our <span>Services</span></h1>
            <div 
                className="button-container1 content-center">
                <div
                    className={`btn-gradient gradient2 ${activeSection === 'video-production' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('video-production')}
                    >
                    Video Production
                </div>
                <div
                    className={`btn-gradient gradient2 ${activeSection === '2d&3d-animation' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('2d&3d-animation')}
                    >
                    2D & 3D Animation
                </div>
                <div
                    className={`btn-gradient gradient2 ${activeSection === 'branding' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('branding')}
                    >
                    Branding
                </div>
                <div
                    className={`btn-gradient gradient2 ${activeSection === 'digital-marketing' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('digital-marketing')}
                    >
                    Digital Marketing
                </div>
                <div
                    className={`btn-gradient gradient2 ${activeSection === 'web-development' ? 'active' : ''}`}
                    onClick={() => handleSectionChange('web-development')}
                    >
                    Web Development
                </div>
            </div>
                {activeSection === "2d&3d-animation" && (
                    <div>
                    <motion.Container 
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: false, amount: 0.5 }}
                        className="service__details">
                    <Row className="section">
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: 0.2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={twoD_1} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: 0.2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Explainer Videos</motion.h4>
                                </div>
                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        We simplify the complex, turning your big ideas into bite-sized brilliance. 
                                        Our explainer videos make your message stick.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: 0.3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={twoD_2} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: 0.3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Infographic Videos</motion.h4>
                                </div>

                                <div className="section__back">
                                <div className="section__item-content">
                                    <h6 className="section__details text-center">
                                    Data with a dash of flair! Our infographic videos transform numbers 
                                into eye-popping visuals that demand attention.
                                    </h6>
                                </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={twoD_3} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Training Videos</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Turn training into a thrill! Our videos make learning engaging, 
                                        ensuring your team is sharp, skilled, and ready to shine.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .5
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={twoD_4} alt="" className="w-100" />
                                    </div>
                                     <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .5
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Promotional Videos</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Your brand deserves the spotlight. Our promotional videos are 
                                        designed to dazzle and drive action, making sure you steal the show.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .6
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={twoD_5} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .6
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Social Video Ads</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Get your scroll-stopping content here! Our social video ads 
                                        are crafted to captivate, engage, and convert one view at a time.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .7
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={twoD_6} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .7
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Educational Videos</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        We make learning look good! Our educational videos transform 
                                        lessons into captivating experiences that stick with your audience.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .8
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={twoD_7} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .8
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Storytelling Videos</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Stories that stick. Our storytelling videos weave your brand's 
                                        narrative into something unforgettable, turning viewers into fans.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .9
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={twoD_8} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .9
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Case Study Videos</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Success looks good on you! Our case study videos showcase your wins, 
                                        turning your achievements into powerful stories of impact.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                    </Row>
                </motion.Container>
                <div className="button-container content-center">
                    <a href="#about-contact"><button className="btn button-touch2">Get a Quote
                            <span className="line">
                                <span className="circle"></span>
                            </span>
                    </button></a>
                </div>
                </div>
                )}

                {activeSection === "video-production" && (
                    <div>
                    <motion.Container 
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: false, amount: 0.5 }}
                        className="service__details">
                    <Row className="section">
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .1
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={video_1} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .1
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">TV Production</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        We have the secret ingredient to take along the TV production process strategically.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={video_2} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Film Production</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        We speak the film production language that makes us the 
                                        leading videography company.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={video_3} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Animated Videos</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Animated videos tell stories and share information 
                                        in a way that appeals.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={video_4} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Event Coverage</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Making memories that last proves to be your ideal 
                                        option for event videography services.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                    </Row>
                </motion.Container>
                <div className="button-container content-center">
                    <a href="#about-contact"><button className="btn button-touch2">Get a Quote
                            <span className="line">
                                <span className="circle"></span>
                            </span>
                    </button></a>
                </div>
                </div>
                )}

                {activeSection === "digital-marketing" && (
                    <div>
                    <motion.Container 
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: false, amount: 0.5 }}
                        className="service__details">
                    <Row className="section">
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .1
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={digital_1} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .1
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">SEO & SMO</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Enhance your online visibility with our targeted 
                                        search engine optimization and social media optimization strategies.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={digital_2} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Social Media</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        oost your brand’s engagement through expertly crafted 
                                    social media content and campaigns.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div 
                                data-aos="zoom-in-up"
                                data-aos-once="true"
                                data-aos-delay="300"
                                className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={digital_3} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Google Adwords</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Drive targeted traffic and maximize ROI with 
                                    our data-driven Google AdWords strategies.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div 
                                data-aos="zoom-in-left"
                                data-aos-once="true"
                                data-aos-delay="400"
                                className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={digital_4} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Email Marketing</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Reach and engage your audience with personalized 
                                        and effective email marketing campaigns.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                    </Row>
                </motion.Container>
                <div className="button-container content-center">
                    <a href="#about-contact"><button className="btn button-touch2">Get a Quote
                            <span className="line">
                                <span className="circle"></span>
                            </span>
                    </button></a>
                </div>
                </div>
                )}

                {activeSection === "branding" && (
                    <div>
                    <motion.Container 
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: false, amount: 0.5 }}
                        className="service__details">
                    <Row className="section">
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .1
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={branding_1} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .1
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Brand Audits</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Unlock the potential of your brand with a comprehensive brand audit. 
                                        We dive deep into your brand’s performance, uncovering strengths, gaps, and opportunities to elevate your market presence.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={branding_2} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Brand Positioning</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Strategically positioning your brand to stand out in a crowded market is our forte. 
                                    We craft a unique identity for your business that resonates with your target audience and sets you apart from the competition.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div 
                                data-aos="fade-down"
                                data-aos-once="true"
                                data-aos-delay="300"
                                className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={branding_3} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Brand Identity</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Your brand identity is more than just a logo—it’s how the world perceives you. 
                                        We create a cohesive brand image that reflects your values, vision, and purpose, making sure your audience connects with your brand instantly.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div 
                                data-aos="fade-down-left"
                                data-aos-once="true"
                                data-aos-delay="400"
                                className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={branding_4} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Brand Design</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        We blend creativity with strategy to deliver exceptional brand 
                                    designs that are visually striking and strategically effective, ensuring your brand shines and grow across all touchpoints.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div 
                                data-aos="fade-up-right"
                                data-aos-once="true"
                                data-aos-delay="size0"
                                className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .5
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={branding_5} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .5
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Logo Design</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        A logo is the face of your brand. We design logos that are not only memorable 
                                    but tell your brand’s story in an instant. Stand out with a logo that speaks volumes.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div 
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="600"
                                className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .6
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={branding_6} alt="" className="w-100" />
                                    </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .6
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Packaging Design</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Packaging those pops! We craft packaging designs that don’t just hold your product 
                                    but tell your brand story. Stand out on the shelf with packaging that converts.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div 
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="700"
                                className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .7
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={branding_7} alt="" className="w-100" />
                                    </div>
                                        <motion.h4 
                                            initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .7
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            className="section__title text-center">UI & UX Design</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        We design user interfaces that are not only aesthetically pleasing but also enhance the user experience. 
                                    Our focus is on creating intuitive and seamless digital interactions that keep users engaged.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div 
                                data-aos="fade-up-left"
                                data-aos-once="true"
                                data-aos-delay="800"
                                className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .8
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={branding_8} alt="" className="w-100" />
                                    </div>
                                        <motion.h4 
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .8
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            className="section__title text-center">Web Design</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Your website is your brand’s digital storefront. We build sleek, intuitive, 
                                    user-friendly websites that engage visitors, drive results, and perfectly reflect your brand’s goals and personality.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                    </Row>
                </motion.Container>
                <div className="button-container content-center">
                    <a href="#about-contact"><button className="btn button-touch2">Get a Quote
                            <span className="line">
                                <span className="circle"></span>
                            </span>
                    </button></a>
                </div>
                </div>
                )}

                {activeSection === "web-development" && (
                    <div>
                    <motion.Container 
                        variants={gridContainerVariants}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: false, amount: 0.5 }}
                        className="service__details">
                    <Row className="section">
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .1
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={web_1} alt="" className="w-100" />
                                    </div>
                                        <motion.h4 
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .1
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            className="section__title text-center">Custom Design</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Unique, responsive designs that reflect your brand’s 
                                    identity and captivate your audience.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={web_2} alt="" className="w-100" />
                                    </div>
                                        <motion.h4 
                                            initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .2
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            className="section__title text-center">E-Commerce Solutions</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Scalable and secure online stores designed for 
                                    seamless shopping experiences.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={web_3} alt="" className="w-100" />
                                    </div>
                                        <motion.h4 
                                            initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .3
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            className="section__title text-center">Web Applications</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Tailored web applications to enhance productivity 
                                    and streamline processes.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={web_4} alt="" className="w-100" />
                                    </div>
                                        <motion.h4 
                                            initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .4
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            className="section__title text-center">CMS Integration</motion.h4>
                                </div>
                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Easy-to-manage content management systems for 
                                    efficient website updates and control.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                    <div className="section__img">
                                        <motion.img 
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .5
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            src={web_5} alt="" className="w-100" />
                                    </div>
                                        <motion.h4 
                                            initial={{
                                                opacity: 0,
                                                x: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .5
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                            className="section__title text-center">SEO Optimization</motion.h4>
                                </div>

                                <div className="section__back">
                                    <div className="section__item-content">
                                        <h6 className="section__details text-center">
                                        Built-in search engine optimization to boost visibility 
                                    and drive organic traffic to grow your business.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                <div className="section__img">
                                    <motion.img 
                                        initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .6
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        src={web_6} alt="" className="w-100" />
                                </div>
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .6
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Website Maintenance</motion.h4>
                                </div>

                                <div className="section__back">
                                <div className="section__item-content">
                                    <h6 className="section__details text-center">
                                    Ongoing maintenance, security updates, and performance 
                                monitoring to keep your site running smoothly.
                                    </h6>
                                </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                <div className="section__img">
                                    <motion.img 
                                        initial={{
                                                opacity: 0,
                                                y: -size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .7
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        src={web_7} alt="" className="w-100" />
                                </div>
                               
                                    <motion.h4 
                                        initial={{
                                                opacity: 0,
                                                y: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .7
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        className="section__title text-center">Shopify Development</motion.h4>
                                </div>

                                <div className="section__back">
                                <div className="section__item-content">
                                    <h6 className="section__details text-center">
                                    Custom Shopify Development tailored to your brand, delivering seamless 
                                    eCommerce experiences that drive conversions and growth.
                                    </h6>
                                </div>
                                </div>
                            </div>
                        </motion.Col>
                        <motion.Col lg="4" md="4" sm="6" 
                            variants={gridSquareVariants}
                            viewport={{ once: false, amount: 0.5 }}
                            className="mb-5 col-padding">
                            <div className="section__item">
                                <div className="section__front">
                                <div className="section__img">
                                    <motion.img 
                                        initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .8
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                        src={web_8} alt="" className="w-100" />
                                </div>
                                <motion.h4 
                                    initial={{
                                                opacity: 0,
                                                x: size
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                x: 0
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: "easeOut",
                                                delay: .8
                                            }}
                                            viewport={{ once: false, amount: 0.5 }}
                                    className="section__title text-center">Enterprise Solutions</motion.h4>
                                </div>

                                <div className="section__back">
                                <div className="section__item-content">
                                    <h6 className="section__details text-center">
                                    Scalable Enterprise Solutions designed to streamline operations, 
                                    enhance productivity, and drive business transformation.
                                    </h6>
                                </div>
                                </div>
                            </div>
                        </motion.Col>
                    </Row>
                </motion.Container>
                <div className="button-container content-center">
                    <a href="#about-contact"><button className="btn button-touch2">Get a Quote
                            <span className="line">
                                <span className="circle"></span>
                            </span>
                    </button></a>
                </div>
                </div>
                )}
    </section>
  )
}

export default Services